<template>
  <div class="home-con">
    <van-list
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <van-cell v-for="item in list" :key="item.create_time" :title="(item.method==1?'充值 +':'消费 -') + item.amount" 
      :label="item.comment" :value="item.create_time_str" />
    </van-list>
  </div>
</template>

<script>
import api from "@/api";
import { Toast } from 'vant';
import { Dialog } from 'vant';
export default {
  name: 'Home',
  data(){
    return {
      list: [],
      loading: false,
      finished: false,
    }
  },
  computed:{

  },
  components: {

  },
  methods:{
    showTitle(){

    },
    onClickLeft(){
      this.$toast('点左侧')
      this.$router.go(-1)
    },
    onClickRight(){
      this.$toast('点右侧')
    },
    onLoad() {
      let opt = {
        payment_user:'admin'
      }
      this.loading = true
      api.payment.logs(opt).then(res=>{
        this.list = res.data.data;
        this.finished = true
      })
    },
  },
  mounted(){

  }
}
</script>
<style lang="scss" scoped>
.home-con{
  // .cell-title{
  //   text-align: left;
  // }

  width: 100vw;
  height: 100vh;
  background-color: #fafafa;
}
</style>